import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import UserContext from '../../utils/context/user/userContext.js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from 'react-bootstrap';
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { authenticate } from '../../services/authenticate';
import { validate } from '../../services/validate.js';

import userpool from '../../config/userpool';

const Login = () => {

  const Navigate = useNavigate();

  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const userContext = useContext(UserContext);

  useEffect(()=>{
    let user = userpool.getCurrentUser();
    if(user){
      userContext.handleUserLogout();    
    }
  },[]);

  const [newPasswordRequired,setNewPasswordRequired] = useState(false);
  const [userAttr, setUserAttr] = useState();
  const [cognitoUser, setCognitoUser] = useState();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');

  const changePassword = () => {
    if (newPassword === newPasswordCheck){
      cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: result => {
          userContext.handleUserNewPassword( emailInput, newPassword );
          setNewPasswordRequired(false);
          Navigate('/dashboard');
        },
        onFailure: (err) => {
          console.log("New password update failed", err);
        },
      });
    } else {
      alert('Passwords do not match');
    }
  };

  const onErrorDismiss = () => {
    let data = {
      user: {email: '', password: ''},
      isAth: false,
      error: ""
    }
    userContext.handleUserLogin( data );
  }

  const onNewPasswordRequired = (userAttr) => {
    setNewPasswordRequired(true);
    setUserAttr(userAttr);
  };
  
  const handleSubmit = async e => {
    e.preventDefault();
    validate(emailInput, passwordInput)
      .then( async (validationRes) => {
        if (validationRes.error === '') {
          await authenticate( validationRes, setCognitoUser, onNewPasswordRequired )
          .then((res)=>{
            userContext.handleUserLogin( res );
            Navigate('/dashboard');
          },(err)=>{
            console.log("LOGIN ERROR: " , err);
            const err_msg = JSON.stringify(err.error, Object.getOwnPropertyNames(err.error))
            if (err_msg.includes("User is not confirmed")){
              userContext.handleUserConfirm( err );
              Navigate('/confirm');
            } else {
              userContext.handleUserLogin( err );
            }

          })
          .catch(err=>console.log(err))
        }
      }, err => console.log(err))
      .catch(err => console.log(err));
  }

  return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-gray-lc-300 p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    {
                        !newPasswordRequired ?
                        <h3 className="mb-0 text-secondary-lc">Login</h3>
                        :
                        <h3 className="mb-0 text-secondary-lc">New password required</h3>
                    }
                  </div>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label className="text-dark-lc">Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} className="text-secondary-lc" />
                        </InputGroup.Text>
                        <Form.Control autoFocus required placeholder="example@company.com" onChange={({ target }) => setEmailInput(target.value)}/>
                      </InputGroup>
                    </Form.Group>

                    {
                      !newPasswordRequired ?
                     <> 
                      <Form.Group>
                        <Form.Group id="pswd" className="mb-4">
                          <Form.Label className="text-dark-lc">Password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                            </InputGroup.Text>
                            <Form.Control required autoComplete="pswd" type="password" placeholder="Password" onChange={({ target }) => setPasswordInput(target.value)} />
                          </InputGroup>
                        </Form.Group>
                      </Form.Group>
                      <Button style={{margin:'10px'}} variant="primary" onClick={()=>Navigate('/')}>
                        Home
                      </Button>
                      <Button variant="primary" type="submit" >
                        Login
                      </Button>
                    </>
                    : 
                    <> 
                      <Form.Group>
                        <Form.Group id="newPswd" className="mb-4">
                          <Form.Label className="text-dark-lc">Password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                            </InputGroup.Text>
                            <Form.Control required autoComplete="pswd" type="password" placeholder="New password" onChange={({ target }) => setNewPassword(target.value)} />
                          </InputGroup>
                          <Form.Label style={{margin:'10px'}} className="text-dark-lc">Confirm password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                            </InputGroup.Text>
                            <Form.Control required autoComplete="pswd" type="password" placeholder="Repeat new password" onChange={({ target }) => setNewPasswordCheck(target.value)} />
                          </InputGroup>
                        </Form.Group>
                      </Form.Group>
                      <Button style={{margin:'10px'}} variant="primary" onClick={()=>Navigate('/')}>
                        Home
                      </Button>
                      <Button variant="primary" onClick={()=>changePassword()}>
                        Update password
                      </Button>
                    </>
                    }
                    {
                      (!newPasswordRequired && userContext.error) &&
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Alert variant="danger" dismissible={true} onClose={() => onErrorDismiss()}>
                            {JSON.stringify(userContext.error, Object.getOwnPropertyNames(userContext.error))}
                        </Alert>
                      </div>
                    }
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
  )
}

export default Login