import {LOGIN, LOGOUT, CONFIRM, NEWPASSWORD} from "./actionTypes"

export const login = (data) => {
  return {
    type: LOGIN,
    user: data.user,
    error: data.error
  };
};
  
export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const confirm = (data) => {
  return {
    type: CONFIRM,
    user: data.user
  };
}

export const new_password = (email, new_password) => {
  return {
    type: NEWPASSWORD,
    user: { email: email, password: new_password }
  };
}
