import React from "react";
import Header from '../Header';
import Home from '../Home';

const HomePage = () => {

    return (
        <>
        <Header></Header>
        <Home></Home>
        </>
    )
}

export default HomePage