import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from '../config/userpool';

export const authenticate = ( data, setCognitoUser, onNewPasswordRequired ) => {

    return new Promise( (resolve,reject ) => {
        
        const cognitoUser = new CognitoUser({
            Username: data.user.email,
            Pool:userpool
        });

        setCognitoUser(cognitoUser);

        const authDetails= new AuthenticationDetails({
            Username: data.user.email,
            Password: data.user.password,
        });

        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
                const authRes = {
                    user: data.user,
                    isAuth: true,
                    error: ""
                }
                resolve(authRes);
            },
            onFailure: (err) => {
                console.log("AUTHENTICATION ERROR: ", err);
                const authRes = {
                    user: data.user,
                    isAuth: false,
                    error: err
                }
                reject(authRes);
            },
            newPasswordRequired: (userAttributes) => {
                onNewPasswordRequired(userAttributes);
            },
        });
    });
};

