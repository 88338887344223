import React from 'react';
import { Routes , Route } from "react-router-dom";
import { AppRoutes } from "../../config/routes";
import HomePage from '../pages/HomePage';
import Signup from '../pages/Signup';
import Confirm from '../pages/Confirm'
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';

const AppRouter = () => {
  return(
    <Routes>
        <Route exact path={AppRoutes.Home.path} element={<HomePage />}/>
        <Route exact path={AppRoutes.Signup.path} element={<Signup />}/>
        <Route exact path={AppRoutes.Confirm.path} element={<Confirm/>}/>
        <Route exact path={AppRoutes.Login.path} element={<Login />}/>
        <Route exact path={AppRoutes.Dashboard.path} element={<Dashboard/>}/>

        <Route path="/*" element={<NotFound/>}/>
    </Routes>
  )
};

export default AppRouter;