import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const Navigate=useNavigate();
    return (
        <>
        <div className='home'>
            <h3>Error 404</h3>
        </div>
        </>
    )
}

export default NotFound