import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../utils/context/user/userContext.js';

import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from 'react-bootstrap';
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { validate } from '../../services/validate';

import userpool from '../../config/userpool';

const Signup = () => {
  
  const Navigate = useNavigate();

  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');

  const userContext = useContext(UserContext);

  //const [cognitoUser, setCognitoUser] = useState();
  
  const onErrorDismiss = () => {
    let data = {
      user: {email: '', password: ''},
      isAth: false,
      error: ""
    }
    userContext.handleUserLogin( data );
  }

  const handleSubmit = async e => {
    e.preventDefault();
    validate(emailInput, passwordInput)
      .then( async (validationRes) => {
        if (validationRes.error === '') {
          if (validationRes.user.password === passwordCheck){
            const attributeList = [];
            attributeList.push(
              new CognitoUserAttribute({
                Name: 'email',
                Value: emailInput,
              })
            );
            userpool.signUp(validationRes.user.email, validationRes.user.password, attributeList, null, (err, data) => {
              if (err) {
                console.log("SIGN UP ERROR: " , err);
                alert("Couldn't sign up");
              } else {
                alert('User Added Successfully');
                userContext.handleUserLogin( validationRes );
                Navigate('/confirm');
              }
            });
          } else {
            alert("Password entries do not match");
          }
        }
      }, err => console.log(err))
      .catch(err => console.log(err));
  }

  return (
    <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-gray-lc-300 p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0 text-secondary-lc">Sign up</h3>
                  </div>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label className="text-dark-lc">Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} className="text-secondary-lc" />
                        </InputGroup.Text>
                        <Form.Control autoFocus required placeholder="example@company.com" onChange={({ target }) => setEmailInput(target.value)}/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="pswd" className="mb-4">
                        <Form.Label className="text-dark-lc">Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                          </InputGroup.Text>
                          <Form.Control required autoComplete="pswd" type="password" placeholder="Password" onChange={({ target }) => setPasswordInput(target.value)} />
                        </InputGroup>
                        <Form.Label className="text-dark-lc">Repeat password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                          </InputGroup.Text>
                          <Form.Control required autoComplete="pswd" type="password" placeholder="Repeat password" onChange={({ target }) => setPasswordCheck(target.value)} />
                        </InputGroup>
                      </Form.Group>
                      {
                        userContext.error &&
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Alert variant="danger" dismissible={true} onClose={() => onErrorDismiss()}>
                              {userContext.error}
                          </Alert>
                        </div>
                      }
                    </Form.Group>
                    <Button style={{margin:'10px'}} variant="primary" onClick={()=>Navigate('/')}>
                      Home
                    </Button>
                    <Button variant="primary" type="submit" >
                      Sign up
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
  )
}

export default Signup