
export const validate = (emailInput, passwordInput) => {
    
    return new Promise((resolve, reject) => {
        let data = {};
        if (emailInput === '' && passwordInput === '') {
            data = {
                user: {email: '', password: ''}, 
                error: "Email is Required and Password is required"
            }
        }
        else if (emailInput === '') {
            data = {
                user: {email: '', password: ''},
                error: "Email is Required"
            }
        }
        else if (passwordInput === '') {
            data = {
                user: {email: '', password: ''},
                error: ""
            }
        }
        else if (passwordInput.length < 6) {
            data = {
                user: {email: '', password: ''},
                error: "Password must be 6 character"
            }
        }
        else {
            data = {
                user: {email: emailInput, password: passwordInput}, 
                error: ""
            }
        }
        resolve(data);
    });
    };


