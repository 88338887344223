import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../utils/context/user/userContext.js';

import { CognitoUser } from 'amazon-cognito-identity-js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from 'react-bootstrap';
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import userpool from '../../config/userpool.js';

const Confirm = () => {

  const userContext = useContext(UserContext);
  const Navigate = useNavigate();

  const [emailInput, setEmailInput] = useState('');
  const [verificationCodeInput, setVerificationCodeInput] = useState('');

  const [verificationResult, setVerificationResult] = useState('');

  const onErrorDismiss = () => {
  }

  useEffect(()=>{
    setEmailInput(userContext.user.email);
  },[]);

  const handleSubmit = async e => {
    e.preventDefault();
    const user = new CognitoUser({
      Username: emailInput,
      Pool: userpool,
    });
    user.confirmRegistration(verificationCodeInput, true, (err, data) => {
      if (err) {
        console.log("CONFIRM ERROR: " , err);
        alert("Couldn't verify account");
      } else {
        alert('Account verified successfully');
        window.location.href = '/login';
      }
    });
  };

  return (
    <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow-soft border rounded border-gray-lc-300 p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0 text-secondary-lc">Confirm email</h3>
                  </div>
                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label className="text-dark-lc">Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon value icon={faEnvelope} className="text-secondary-lc" />
                        </InputGroup.Text>
                        <Form.Control autoFocus required placeholder="example@company.com" value={emailInput} onChange={({ target }) => setEmailInput(target.value)}/>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="pswd" className="mb-4">
                        <Form.Label className="text-dark-lc">Verification code</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} className="text-secondary-lc"/>
                          </InputGroup.Text>
                          <Form.Control autoFocus required placeholder="Verification code here... check your inbox" onChange={({ target }) => setVerificationCodeInput(target.value)}/>
                        </InputGroup>
                      </Form.Group>
                      {
                        (verificationResult && userContext.error) &&
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Alert variant="danger" dismissible={true} onClose={() => onErrorDismiss()}>
                            {JSON.stringify(userContext.error, Object.getOwnPropertyNames(userContext.error))}
                          </Alert>
                        </div>
                      }
                    </Form.Group>
                    <Button style={{margin:'10px'}} variant="primary" onClick={()=>Navigate('/')}>
                      Home
                    </Button>
                    <Button variant="primary" type="submit" >
                      Verify account
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
  )
}

export default Confirm