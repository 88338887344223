import React from "react";

import '../css/home.css';

const Home = () => {

    return (
        <div className='home'>
            <img src="./image_300.jpg" alt="Light Ray" className='p-6' width={700}/>
        </div>
    )
}

export default Home