import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import userpool from '../../config/userpool';

import Header from "../Header";
import QuestionForm from "../QuestionForm";

import UserContext from "../../utils/context/user/userContext";

const Dashboard = () => {

  const userContext = useContext(UserContext);

  const Navigate = useNavigate();

  let username = "uSeR";
  if (userContext.user)
    username= userContext.user.email;

  useEffect(()=>{
    let user = userpool.getCurrentUser();
    if(!user){
      Navigate('/');
    }
  },[]);

  return (
    <>
    <div className="chat">
      <Header/>
      <QuestionForm username={username} />
    </div>
    </>
  )
}

export default Dashboard