import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from 'react-router-dom';
import UserContext from "../utils/context/user/userContext";
import { AppRoutes } from "../config/routes";

import "../css/header.css"; // Import the custom styles

const Header = () => {

  const userContext = useContext(UserContext);
  const Navigate = useNavigate();

  const onLogout = (e) => {
      e.preventDefault();
      Navigate(AppRoutes.Home.path);
      userContext.handleUserLogout();
  }

  return (
    <div className="header">
        <div className="d-flex flex-column p-2">

          <img className="logo" src="./Lighcast_RGB_Lockup_Color_full.png" alt="Lightcast logo" />
          
          <h1>Light Ray</h1>
          <div className="d-grid gap-2 d-md-block header-button-section">
                  { 
                    userContext.isAuth ? 
                    <div className="header-button-log">
                      <Button style={{margin:'10px'}} variant="primary" onClick={onLogout}>
                          Logout
                      </Button>
                    </div>
                    : 
                    <div className="header-button-log">
                      <Button style={{margin:'10px'}} variant="primary" onClick={()=>Navigate('/signup')}>
                          Sign up
                      </Button>
                      <Button style={{margin:'10px'}} variant="primary" onClick={()=>Navigate('/login')}>
                          Login
                      </Button>
                    </div>
                  } 
                </div>
        </div>
    </div>
  );
};

export default Header;
