function setYAxisOffset(width) {
    if (width<=576) {
      return 0
    } else if (width<=768) {
      return 220
    } else if (width<=992) {
      return 250
    } else if (width<=1200) {
      return 300
    } else {
      return 310
    }
}

const offset = setYAxisOffset(window.innerWidth);

export const barsOptions = {
  axisY: {
    offset: offset,
  },
  axisX: {
    display: true,
    onlyInteger: true,
    offset: 0
  },
  scales: {
    xAxes: [{
        ticks: {
            display: false
        }
    }]
  },
  chartPadding: {
    top: 0,
    right: 20,
    bottom: 0,
    left: 10
  },
  reverseData: false
};
