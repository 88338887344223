import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "../css/question-form.scss"; // Import the custom styles
import "../css/spinner.css"; // Import the spinner styles
import { REACT_APP_API_ANSWER_URL, REACT_APP_API_QUESTION_URL, REACT_APP_API_TIMEOUT } from "../config/env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { BarChartWidget } from "@bge/global_solution_workbench_commons";
import { barsOptions } from "../config/chartOptions";
import { MapWidget } from "@bge/global_solution_workbench_commons";

const QuestionForm = (props) => {
 
  const username = props.username ? props.username : "USER";

  const [chatLog, setChatLog] = useState([]);
  
  const [question, setQuestion] = useState("");

  const [mapData, setMapData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isAutoQuering, setIsAutoQuering] = useState(false); 
  const [requestId, setRequestId] = useState(null); 
  const executed = useRef(false);
  const lightRay = useRef(false);
  const question2executed = useRef(false);

  const headersGeo = {
      "x-api-key": "VPJymMIbDkD6xzgTB9Ee6l5QcKsdVxh1jkLsUiwf",
      "Content-Type" : "application/json"
  };


  const headers = {
    'Content-Type': "application/json", 
    "x-api-key": "49db5htbCB441Ta4lmJvc4UOhUWVNB7A6tACO7zI"
  }

  const link = 'https://004alex0qi.execute-api.eu-west-1.amazonaws.com/Prod/gson';
  
  const dataExtracion = (stringData) => {

    let datas = stringData.replace(/[()]/g, '');
    datas = JSON.parse(datas.replace(/['']/g, '"'));
    
    const finalResult = [[],[]];

    for(let i = 0; i < datas.length; i+=2){
      const y = i + 1;
      finalResult[0].push(datas[i]);
      finalResult[1].push(datas[y]);
    }

    return finalResult;
  }

  const handleSubmit = async (q) => {

    const passedQuestion = q ? q : question;

    setIsLoading(true);
    setChatLog([
      ...chatLog,
      {
        "question" : passedQuestion
      },
      {}
    ])

    let result = chatLog;

    try {


      let question;
      if(requestId){
        question = await axios.post(REACT_APP_API_QUESTION_URL,  { 
          request_id: requestId,
          question: passedQuestion
        }, { headers });
      }else{
        question = await axios.post(REACT_APP_API_QUESTION_URL,  { 
          question: passedQuestion
        }, { headers });
      }

      const startTime = performance.now();
      let elapsedTime = 0;

      const answerFunction = async() => {
        try{
          if(elapsedTime < REACT_APP_API_TIMEOUT){
            if(!requestId){
              setRequestId(question.data.request_id);
              const r = await axios.post(
                REACT_APP_API_ANSWER_URL, 
                {
                  request_id: question.data.request_id
                }, 
                {
                  headers 
                }
              );
              return r;
            }else{
              const r = await axios.post(
                REACT_APP_API_ANSWER_URL, 
                {
                  request_id: requestId
                }, 
                {
                  headers 
                }
              );
              return r;
            }
          } else {
            return;
          }
        }catch(error){
          if(error.code === "ERR_NETWORK"){
            const endTime = performance.now();
            elapsedTime = endTime - startTime;
            return answerFunction();
          } else {
            throw error;
          }
        }
      }

      const response = await answerFunction();

      // console.log(response);
      let chartData = null;

      if(chatLog.length <= 3 && lightRay.current){
        chartData = dataExtracion(response.data.full_answer.sql_result);
      }

      if(chatLog.length <= 1 && lightRay.current){
        axios.post(link, {
          "include": chartData[0].map(nation => {
            if(nation === "UK"){
              return `EU/GB`;
            }else if(nation === "EL"){
              return `EU/GR`;
            }else{
              return `EU/${nation}`;
            }
          }),
          "exclude": [""]
          }, {headers: headersGeo})
        .then(response => {
          // console.log(response)
            setMapData(response.data.results);
        })
        .catch(error => {
            console.log(error.message)
        });
      }

      result = [
        ...chatLog,
        {
          "question" : passedQuestion
        },{
          "answer" : response.data.full_answer.answer,
          "explanation" : response.data.full_answer.explanation,
          "query" : response.data.full_answer.sql_query,
          "data" : chartData
        }
      ];
      // console.log(result)
      setChatLog(result);

      // setTableInfo(response.data.table_info);
      // setDocTables(response.data.doc_tables);

    } catch (error) {

      result = [
        ...chatLog,
        {
          "question" : passedQuestion
        },
        {
          "error" : `Error: ${error.response?.data?.error || "Something went wrong"}`
        }
      ];

      setChatLog(result);

    } finally {
      setIsLoading(false); // Hide the loading spinner after the API call is done
    }
  }

  const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  function insertQuestion(id, str, callback) {
    let input = document.getElementById(id);
    let index = 0;
    function addLetter() {
      if (index < str.length) {
        input.value = input.value + str[index];
        index++;
        textAreaAdjust(input);
      } else {
        clearInterval(intervalId);
        if (callback) {
          callback();
        }
      }
    }

    let intervalId = setInterval(addLetter, 25);
  }


  const autoQueries = async () => {

    if(executed.current === false){
        lightRay.current = true;
        setIsAutoQuering(true);
        executed.current = true;

        const firsQuestion = async () => {
          const question1 = "How many job advertisements are there in 2023 country by country in Europe?";
          insertQuestion("question", question1, async () => {
            setQuestion(question1); 
            textAreaAdjust(document.getElementById("question"));
            
            await delay(1000);
            
            setChatLog(
              [
                ...chatLog,
                {
                  "question" : question1
                }
              ]
            );

            document.getElementById("question").value = "";
            setQuestion("");
            textAreaAdjust(document.getElementById("question"));
            await handleSubmit(question1);
          });
        }

        firsQuestion();
    }
  }

  const fuseData = (polygons, data) => {
    
    let fusedData = polygons;

    fusedData.features.forEach(polygon => {
      let plygonToCheck = polygon._id;

      if(polygon._id === "gr"){
        plygonToCheck = "el";
      }

      if(polygon._id === "uk"){
        plygonToCheck = "gb";
      }
      
      console.log(plygonToCheck)
      console.log(data[0])
      if(data[0].some(d => d.toLowerCase() === plygonToCheck )){
        const dataPosition = data[0].indexOf(plygonToCheck.toUpperCase());
        polygon.properties = ({...polygon.properties, density: data[1][dataPosition]});
      }
    });

    return fusedData
  }

  const textAreaAdjust = (element) => {
    element.style.height = "20px";
    element.style.height = (element.scrollHeight-20)+"px";
  }

  useEffect(() => {
    if(chatLog.length === 2 && !isLoading && !question2executed.current && lightRay.current){
      question2executed.current = true;
      const question2 = "What are the 10 most requested skills in Europe in 2023 in job advertisements?";
      insertQuestion("question", question2, async () => {
        setQuestion(question2); 
        textAreaAdjust(document.getElementById("question"));
        await delay(2000);
        // console.log(chatLog)
        setChatLog(
          [
            ...chatLog,
            {
              "question" : question2
            }
          ]
        );
        document.getElementById("question").value = "";
        setQuestion("");
        textAreaAdjust(document.getElementById("question"));
        await handleSubmit(question2);
        setIsAutoQuering(false);
      });
    }
  });

  useEffect(() => {
    let chatLogDiv = document.getElementById('section-results');
    if(chatLogDiv)
      chatLogDiv.scrollTo({
        top: chatLogDiv.scrollHeight,
        behavior: 'smooth'
      });
  },[chatLog])

  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();
      document.getElementById("question").value = "";
      setQuestion("");
      handleSubmit();
    }
  };


  return (
    <div className="chat-content">
        <div className='chat_left'>
          {
            chatLog.length > 0 &&
            <img src="./image_300.jpg" alt="Light Ray" className='chat_mascotte' onClick={() => autoQueries()} />
          }
        </div>
        <div className='chat_center'>
        <div className="form-container">
            {/* <label htmlFor="question">Enter your question here:</label> */}
            <div className="section-results" id="section-results">
            {
              chatLog.length ?
                chatLog.map((message, x) => 
                <div className="result-container" key={`message-${x}`}>
                  <div className="message-owner-section">
                    <button className={`image-${ message.question ? "user" : "light"}`}></button>
                    <i>- { message.question ? username : "Light Ray"} - </i>
                  </div>
                  <div id={(x === (chatLog.length -1) ? "final-message" : x)} className={`message-content-section` +  (x === (chatLog.length -1) ? "-final" : "")}>
                    {
                      x === (chatLog.length -1) && isLoading ?
                        <div className="spinner-container">
                          <div className="spinner"></div>
                        </div>
                      : message.question ?
                        <div className="explanation-container">
                          <p>
                            <span>
                              {message.question}
                              <br />
                            </span>
                          </p>
                        </div>
                      : message.error ?
                        <div className="explanation-container">
                          <p>
                            <span>
                              {message.error}
                              <br />
                            </span>
                          </p>
                        </div>
                      :
                      <>
                        <div className="answer-container">
                          {message.answer && 
                              Array.isArray(message.answer) ?
                              <div className="text"  dangerouslySetInnerHTML={{ __html: message.answer[0]}} />
                              :
                              <div className="text"  dangerouslySetInnerHTML={{ __html: message.answer.substring(0, 1) === '\n' ? message.answer.substring(1).replace(/\n/g, '&emsp;&emsp;') : message.answer.replace(/\n/g, '&emsp;&emsp;')}} />   
                          }
                        </div>
                        {
                          (message.data !== null && x === 1) &&
                            <div className="map-container"> 
                              {
                                mapData ?
                                <MapWidget
                                    mapKey="map"
                                    mapState={fuseData(mapData, message.data)}
                                    tileLayerUrl="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                                    mapStyle={{ width: '100%', height: '50vh'}}
                                    zoom = {
                                        {
                                            level: 0,
                                            max: 10,
                                            min: 1,
                                            scroll: false,
                                            controller: true
                                        }
                                    }
                                    draggable={false}
                                    mapClass={"map-results"}
                                    tooltipContent={
                                      [
                                          {
                                              title: "Value",
                                              content: "properties.density"
                                          }
                                      ]
                                    }
                                    layerStyle={{
                                      fillOpacity: 1,
                                      weight: 0.5,
                                      opacity: 1,
                                      dashArray: 0,
                                      lineColor: "white"
                                    }}
                                    layerMouseOverStyle={{
                                      color: "#F5556F",
                                      fillOpacity: 1,
                                      weight: 2,
                                      opacity: 1,
                                      dashArray: 0,
                                      lineColor: "white"
                                    }}
                                    defaultMapGradient="#F5556F"
                                />
                                : 
                                <div className="spinner" />
                              }
                            </div>
                        }
                        {
                          (message.data !== null && x === 3) && 
                          <div className="chart-container"> 
                              <BarChartWidget 
                                passedItems={{
                                  "labels": message.data[0],
                                  "series": [message.data[1]]
                                }}
                                title = "User logins"
                                subtitle = "User logins of this week"
                                chartClassName="ct-square"
                                showTitle={false}
                                showSubtitle={false}
                                printWidget={false}
                                verticalOrientation={false}
                                options={barsOptions}
                              />
                          </div>
                        }
                    </>
                  }
                  </div>
                </div>
              )
              :
              <div className="section-empty">
                <img src="./image_300.jpg" alt="Light Ray" className='chat_mascotte' onClick={() => autoQueries()} />
                <b>Click me to start...</b>
              </div>
            }
            </div>
            <div className={`section-question` + (isAutoQuering ? " section-disabled" : "")}>
              <div className="section-separator" />
              <textarea
                autoComplete="off"
                type="text"
                id="question"
                name="question"
                placeholder="Write your question here..."
                onChange={(e) => { setQuestion(e.target.value); textAreaAdjust(e.target)}}
                onKeyDown={handleKeyDown}
              />
              <button type="submit" id="send-button" className="send-question" disabled={question === "" || isLoading} onClick={() => {
                  setChatLog(
                    [
                      ...chatLog,
                      {
                        "question" : document.getElementById("question").value
                      }
                    ]
                  );
                  handleSubmit();
                  document.getElementById("question").value = "";
                  textAreaAdjust(document.getElementById("question"));
                }
              }>
                <FontAwesomeIcon icon={faPaperPlane} className="send-icon" /> 
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default QuestionForm;
