import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate } from 'react-router-dom';

import AppRouter from "./components/router/AppRouter"
import UserContextState from "./utils/context/user/userContextStateConfig";

import './css/App.css';

import userpool from './config/userpool';

function App() {

  const [startingState, setStartingState] = useState({user: null, isAuth: false, error: ""});

  useEffect(()=>{
    let user=userpool.getCurrentUser();
      if(user){
        <Navigate to="/dashboard" replace />
      }
  },[]);

  return (
    <UserContextState start={startingState}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>                     
    </UserContextState>
  );
}

export default App;
