import React, { useReducer } from "react";
import UserContext from "./userContext";
import * as ACTIONS from "../../actions/actions";
import * as AuthReducer from "../../reducers/authReducer";

const UserContextState = ({children, start}) => {

  const [stateAuthReducer, dispatchAuthReducer] =  useReducer(
    AuthReducer.AuthReducer,
    start
  );

  const handleLogin = (data) => {
    dispatchAuthReducer(ACTIONS.login(data));
  };

  const handleLogout = () => {
    dispatchAuthReducer(ACTIONS.logout());
  };

  const handleConfirm = (data) => {
    dispatchAuthReducer(ACTIONS.confirm(data));
  };

  const handleNewPassword = (email, newPassword) => {
    dispatchAuthReducer(ACTIONS.new_password(email, newPassword));
  }

  return (
    <UserContext.Provider
      value={{
        isAuth: stateAuthReducer.isAuth,
        user: stateAuthReducer.user,
        error: stateAuthReducer.error,
        handleUserLogin: (data) => handleLogin(data),
        handleUserLogout: () => handleLogout(),
        handleUserConfirm: (data) => handleConfirm(data),
        handleUserNewPassword: (email, newPassword) => handleNewPassword(email, newPassword)
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextState;